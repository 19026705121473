import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserCircle, faCalendarAlt, faComment, faBuilding } from '@fortawesome/free-regular-svg-icons'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
    // Regular icons
    faUserCircle,
    faCalendarAlt,
    faComment,
    faBuilding,
    // Solid icons
    faPen,
    faTrash
)

createApp(App)
.use(router)
.use(library)
.component('font-awesome-icon', FontAwesomeIcon)
.mount('#app')

<template>
    <div v-if="posts?.length === 0">
        <h4>Keine Einträge gefunden...</h4>
    </div>
    <div class="post-list-element" v-for="post in posts" :key="post._id" @click="openPost(post._id)">
        <Post 
        :post="post" 
        @remove-post="$emit('remove-post', post._id)"
        />
    </div>
    <div>
        <h5 v-if="!listfull" class="load-more" @click="$emit('load-more', $event)">Weitere Einträge laden...</h5>
    </div>
</template>
<script>
import Post from './Post'
import router from '../router';

export default {
    name: 'Posts',
    components: {
        Post
    },
    props: {
        msg: String,
        posts: Array,
        listfull: Boolean
    },
    methods: {
        openPost(id) {
            router.push('/post/' + id);
        }
    },
    emits: ['remove-post', 'load-more']
}
</script>
<style>
    .post-list-element:hover {
        background: #f5f5f5;
        cursor: default;
    }

    .load-more:hover {
        color: steelblue;
        text-decoration: underline;
        cursor: pointer;
    }
</style>
<template>
  <div class="home">
    <div id="add-post-form">
      <AddPost @add-post="addPost"/>
    </div>
    <div id="posts">
      <Posts 
      :posts="posts"
      :listfull="listFull"
      @remove-post="removePost"
      @load-more="loadMorePosts"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Posts from '../components/Posts'
import AddPost from '../components/AddPost'
import instance from '../_helpers/axios-instance';

export default {
  name: 'Home',
  components: {
    Posts,
    AddPost
  },
  data() {
    return {
      posts: [],
      listFull: false
    }
  },
  methods: {
        async fetchPosts(limit = 10) {
            const res = await instance.get(`posts`, { params: { sort: '-lastEdit', limit }});
            const data = res.data;
            console.log(data);
            this.listFull = data.count === data.posts.length ? true : false;
            return data.posts
        },
        async loadMorePosts() {
          this.posts = await this.fetchPosts(this.posts.length + 10);
        },
        async removePost(id) {
          if (!confirm('Bist du dir sicher?')) {
            return
          }
          const res = await instance.delete('posts/' + id)

          res.status === 200 ? (this.posts = this.posts.filter((post) => post._id !== id)) : alert('Fehler beim Löschen des Eintrags')
        },
        async addPost(post) {
          const res = await instance({
            url: 'posts',
            method: 'post',
            headers: {
              'Content-Type': 'application/json'
            },
            data: post
          });
          this.posts = [res.data.post, ...this.posts];
        }
    },
    async created() {
        this.posts = await this.fetchPosts();
    }
}
</script>
<style>
  .home {
    display: flex;
    flex-direction: row;
  }

  #add-post-form {
    width: 35%;
    margin: 5px 15px;
  }

  #posts {
    width: 65%;
    margin: 5px 15px;
  }

  @media screen and (max-width: 992px) {
    .home {
      flex-direction: column;
    }

    #add-post-form, #posts {
      width: auto;
    }
  }
</style>

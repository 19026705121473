<template>
    <div v-if="post" class="post">
        <div class="post-header-wrapper">
            <div class="post-header">
                <h3 class="post-title">{{ croppedTitle }}</h3>
                <div class="post-state-wrapper">
                    <span class="post-state"><b>{{ stateMap[post.state] }}</b></span>
                    <span class="post-type-circle" :style="{ 'background-color': post.type && post.type === 'bug' ? 'red' : 'steelblue'}"></span>
                    <span class="post-application-badge">{{ applicationName }}</span>
                    <span class="post-application-badge" v-if="post.component">{{ componentName }}</span>
               </div>
            </div>
            <div class="post-user-action" v-if="currentRoute !== 'Home' && currentRoute">
                <font-awesome-icon class="post-user-action-edit" :icon="['fas', 'pen']" v-if="currentUser.username === post.user.username" @click="editPost($event)" />
                <font-awesome-icon class="post-user-action-delete" :icon="['fas', 'trash']" v-if="currentUser.username === post.user.username" @click="removePost($event)" />
            </div>
        </div>
        
        <p class="post-description" v-if="!editMode" >{{ post.description }}</p>
        <!-- Temporär abgeschaltet (16.07.21)
        <p class="post-description-lastedit" v-if="post.lastEdit && post.lastEdit !== post.created">zuletzt bearbeitet am {{ dateToString(post.lastEdit) }}</p>
         -->
        <div class="post-description-edit" v-if="editMode">
            <textarea v-model="description" rows="3"></textarea>
            <button type="button" class="post-state-edit-btn" @click="editPostDescription">Speichern</button>
        </div>
        
        <div class="bottom-bar">
            <div>
                <span class="bottom-bar-date"><font-awesome-icon :icon="['far', 'calendar-alt']" /> {{ prettyDate }}</span>
                <span class="bottom-bar-comments"><font-awesome-icon :icon="['far', 'comment']" /> {{ post.comments?.length }}</span>
            </div>
            <div>
                <span class="bottom-bar-user"><font-awesome-icon :icon="['far', 'user-circle']" /> {{ post.user.displayName }}</span>
                <span class="bottom-bar-department"><font-awesome-icon :icon="['far', 'building']" /> {{ post.department }}</span>
            </div>
        </div>
    </div>
    <hr/>
</template>
<script>
import {accountService} from '../_services';
import { STATEMAP, Datehelper, Formhelper } from '../_helpers';

export default {
    name: 'Post',
    props: {
        post: Object
    },
    computed: {
        prettyDate: function() {
            const { created } = this.post;
            const d = new Date(created);
            return `${Datehelper.toLocaleDateString(d)} - ${Datehelper.toLocaleTimeString(d)}`;
        },
        croppedTitle: function() {
            if (this.post.title.length < 45 || this.$route.name !== 'Home') {
                return this.post.title
            }
            return this.post.title.substring(0, 42) + '...'
        },
        applicationName: function() {
            return this.formApplications[this.formApplications.findIndex((element) => element.name === this.post.application)].display;
        },
        componentName: function() {
            return this.formApplications[this.formApplications
            .findIndex((element) => element.name === this.post.application)]
            .components[this.formApplications[this.formApplications
            .findIndex((element) => element.name === this.post.application)]
            .components.findIndex((element) => element.name === this.post.component)].display;
        }
    },
    data() {
        return {
            currentUser: null,
            stateMap: STATEMAP,
            formApplications: Formhelper.getApplications(),
            editMode: false,
            description: '',
            currentRoute: this.$route.name
        }
    },
    methods: {
        removePost: function(event) {
            this.$emit('remove-post', this.post._id);
            event.stopPropagation();
        },
        editPost: function(event) {
            this.description = this.post.description;
            this.editMode = true;
            event.stopPropagation();
        },
        editPostDescription: function() {
            if (this.description.trim() === this.post.description.trim()) {
                alert('Keine Änderungen')
                return
            }
            this.editMode = false
            this.$emit('edit-post', { _id: this.post._id, description: this.description })
        },
        dateToString: function(date) {
            return `${Datehelper.toLocaleDateString(date)} - ${Datehelper.toLocaleTimeString(date)}`;
        }
    },
    created() {
        this.currentUser = accountService.accountValue
    },
    emits: ['remove-post', 'edit-post']
}
</script>
<style>
    .post {
        display: block;
        text-align: left;
        margin: 15px 0;
        padding: 5px;
        border-radius: 5px;
    }

    .post-description {
        white-space: pre-wrap;
    }

    .upvoted {
        background: steelblue;
        color: white;
    }

    .upvoted:hover {
        background: white;
        color: black;
        cursor: pointer;
    }

    .post-header-wrapper {
        display: flex;
        flex-direction: row;
        align-items: baseline;
    }

    .post-header {
        padding: 0 10px 0 0;
    }

    .post-state-wrapper {
        display: flex;
        align-items: center;
    }

    .post-user-action {
        margin-left: auto;
    }

    .post-user-action > * {
        margin: 0 10px;
    }

    .post-user-action > *:hover {
        cursor: pointer;
    }

    .post-user-action-edit:hover {
        color: steelblue;
    }

    .post-user-action-delete:hover {
        color: red;
    }

    .post-upvotes {
        border: 2px solid gray;
        border-radius: 7px;
        height: 80px;
        width: 50px;
        font-size: 1.5em;
        display: flex;
        flex-direction: column;
        align-items: center !important;
    }

    .post-upvotes > span {
        margin: auto;
    }

    .post-upvotes:not(.upvoted):hover {
        background: steelblue;
        color: white;
        cursor: pointer;
    }

    .post-type-circle {
        margin: 5px;
        width: 15px;
        height: 15px;
        border-radius: 7.5px;
        display: inline-block;
    }

    .post-application-badge {
        font-size: 12px;
        margin: 0 5px;
        padding: 5px;
        background-color: lightgrey;
        border-radius: 13.5px;
    }

    .post-title {
        margin: 5px;
        display: flex;
        align-items: center;
    }

    .post-state {
        display: inline-block;
        padding: 0 15px;
        height: 40px;
        font-size: 15px;
        line-height: 40px;
        border-radius: 20px;
        background-color: #f1f1f1;
    }

    .bottom-bar > div > span:not(:last-child) {
        margin-right: 15px;
    }

    .bottom-bar {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
    }

    .post-description-lastedit {
        margin-top: 0;
        font-size: 12px;
        color: gray;
        font-style: italic;
    }

    .post-state-edit-btn {
        width: 100%;
        max-width: 200px;
        background-color: steelblue;
        color: white;
        padding: 14px 20px;
        margin: 8px 0;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }
</style>
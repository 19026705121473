export const Datehelper = {
    toLocaleDateString,
    toLocaleTimeString
}

function toLocaleDateString(date) {
    if (typeof date === 'string') {
        date = new Date(date);
    }
    return date.toLocaleDateString('de', { day: '2-digit', month: "2-digit", year: "numeric" });
}

function toLocaleTimeString(date) {
    if (typeof date === 'string') {
        date = new Date(date);
    }
    return date.toLocaleTimeString('de', { hour: '2-digit', minute: '2-digit' });
}
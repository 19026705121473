import axios from 'axios';
import { accountService, localStorageService } from '../_services';

const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL
});

instance.interceptors.request.use(
    (request) => {
        const token = localStorageService.getToken();
        const isLoggedIn = token != null;

        if (isLoggedIn) {
            request.headers.common.Authorization = `Bearer ${token}`;
        }

        return request;
    }
);

function createAxiosResponseInterceptor() {
    const interceptor = instance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            const res = error.response;
            if (res.status !== 401) {
                // Handle errors
                return Promise.reject(error);
            }
    
            instance.interceptors.response.eject(interceptor);
            
            return instance.get(`${process.env.VUE_APP_AUTH_URL}/sso/renewat`, {
                withCredentials: true
            })
            .then(response => {
                const token = response.data.token;
                localStorageService.setToken(token);
                error.response.config.headers['Authorization'] = `Bearer ${token}`;
                return instance(error.response.config);
            })
            .catch(error => {
                accountService.logout();
                return Promise.reject(error);
            })
            .finally(createAxiosResponseInterceptor());
        }
    );
}

instance.interceptors.response.use(createAxiosResponseInterceptor());

export default instance;
<template>
<Navigation />
  <div id="content">
    <router-view></router-view>
  </div>
</template>
<script>
import { accountService } from './_services';
import Navigation from './components/Navigation'

export default {
  beforeCreate() {
    accountService.setAccount();
  },
  components: {
    Navigation
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#content {
  margin: auto;
  max-width: 1024px;
}
</style>

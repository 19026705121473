export const localStorageService = {
    getToken,
    setToken,
    getUserData,
    setUserData
}

const TOKEN_ITEM_NAME = 'kfzapp-idea-token';
const USER_ITEM_NAME = 'kfzapp-idea-user';

function getToken() {
    return localStorage.getItem(TOKEN_ITEM_NAME);
}

function setToken(token) {
    localStorage.setItem(TOKEN_ITEM_NAME, token);
}

function getUserData() {
    const data = localStorage.getItem(USER_ITEM_NAME);
    if (data) {
        return JSON.parse(data);
    }
}

function setUserData(userData) {
    localStorage.setItem(USER_ITEM_NAME, JSON.stringify(userData));
}
<template>
<div id="nav">
    <div>
        <router-link to="/" style="display: flex; align-items: center">
        <img id="logo" src="../assets/logo_blau_weiss_schwarz.png" />
        <span style="font-size: 1.5em; margin-left: 0.5em;">Ideenboard</span>
        </router-link>
    </div>
    <div class="nav-user-dropdown">
        <span class="nav-username">{{ user ? user.vorname : 'Nicht eingeloggt' }}</span>
        <font-awesome-icon :icon="['far', 'user-circle']" size="lg"></font-awesome-icon>
        <div class="nav-user-dropdown-content">
            <span @click="logout">Logout</span>
        </div>
    </div>
</div>
</template>
<script>
import { accountService } from '../_services'
export default {
    name: 'Navigation',
    data() {
        return {
            user: null
        }
    },
    methods: {
        logout() {
            accountService.logout()
        }
    },
    created() {
        accountService.account.subscribe(value => {
            this.user = value
        })
    }
}
</script>
<style lang="scss">
#nav {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: steelblue;
    }
  }
}

.nav-username {
    margin-right: 10px;
}

.nav-user-dropdown {
    padding: 4px;
}

.nav-user-dropdown:hover {
    color: steelblue;
    cursor: pointer;
}

.nav-user-dropdown-content {
    display: none;
    right: 10px;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 8px 12px;
    z-index: 1;
}

.nav-user-dropdown:hover .nav-user-dropdown-content {
  display: block;
}

#logo {
  width: 50px;
}
</style>
import { accountService } from '../_services';

import router from '../router';

export function authGuard() {
    return async () => {
        const account = accountService.accountValue;
        if (account) {
            return true;
        }
        const parsedUrl = new URL(window.location.href);
        const ssoToken = parsedUrl.searchParams.get("ssoToken");
        // SSOToken prüfen und ggf. entsprechende Funktion aufrufen
        if (ssoToken) {
            const status = await accountService.getJwtFromSSOToken(ssoToken);
            if (200 <= status < 300) {
                // Verify token on local backend
                await accountService.verifyTokenLocal();
                router.push('/');
                return true;
            }
            return false;
        }
        window.location=process.env.VUE_APP_AUTH_URL + '/sso/login?serviceURL=' + process.env.VUE_APP_URL
    }
}
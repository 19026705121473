export const Formhelper = {
    getApplications
}

function getApplications() {
    return [
        {
            name: 'kfzapp',
            display: 'Kfzapp',
            components: [
                {
                    name: 'inspektion',
                    display: 'Inspektion'
                },
                {
                    name: 'reifenlager',
                    display: 'Reifenlager'
                },
                {
                    name: 'einlagerung',
                    display: 'Einlagerung'
                },
                {
                    name: 'kundenfahrzeug',
                    display: 'Kunden-/Fahrzeugverwaltung'
                },
                {
                    name: 'dashboard',
                    display: 'Dashboard'
                },
                {
                    name: 'check',
                    display: 'Urlaubs-/Wintercheck'
                }
            ]
        },
        {
            name: 'idea',
            display: 'Ideenboard',
            components: [
                {
                    name: 'main',
                    display: 'Hauptseite'
                },
                {
                    name: 'postdetail',
                    display: 'Idee Detailansicht'
                }
            ]
        }
    ];
}
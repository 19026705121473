<template>
    <h3>Neuer Eintrag</h3>
    <form @submit="onSubmit">
        <label>Typ</label>
        <select name="type" id="type" v-model="type">
            <option value="idea">Idee</option>
            <option value="bug">Fehler/Störung</option>
        </select>

        <label>Titel</label>
        <input type="text" v-model="title" name="title" id="title" placeholder="Titel">

        <label>Beschreibung</label>
        <textarea v-model="description" name="description" id="description" placeholder="Beschreibung" rows="3"></textarea>

        <label>Anwendung</label>
        <select v-model="application" name="application" id="application">
            <option v-for="formApplication in formApplications" :value="formApplication.name" :key="formApplication.name">{{ formApplication.display }}</option>
        </select>

        <label>Komponente</label>
        <select v-model="component" name="component" id="component">
            <option value="">Bitte auswählen...</option>
            <option v-for="component in getActiveApplicationComponents()" :value="component.name" :key="component.name">{{ component.display }}</option>
        </select>

        <input type="submit" value="Absenden">
    </form>
</template>
<script>
import { Formhelper } from '../_helpers';
export default {
    name: 'AddPost',
    data() {
        return {
            type: 'idea',
            title: '',
            description: '',
            application: 'kfzapp',
            prevApplication: 'kfzapp',
            component: '',
            formApplications: Formhelper.getApplications()
        }
    },
    methods: {
        onSubmit(e) {
            e.preventDefault();
            
            if (!this.title) {
                alert('Bitte gib einen Titel ein');
                return;
            }
            if (!this.description) {
                alert('Bitte gib eine Beschreibung ein');
                return;
            }
            const newPost = {
                type: this.type,
                title: this.title,
                description: this.description,
                application: this.application,
                component: this.component
            }

            this.$emit('add-post', newPost);
            this.type = 'idea';
            this.title = '';
            this.description = '';
            this.application = 'kfzapp';
            this.prevApplication = 'kfzapp';
            this.component = '';
        },
        getActiveApplicationComponents() {
            return this.formApplications[this.formApplications.findIndex((element) => element.name === this.application)].components;
        }
    },
    watch: {
        application: function (val) {
            if (val !== this.prevApplication) {
                this.component = "";
                this.prevApplication = val;
            }
        }
    },
    emits: ['add-post']
}
</script>
<style>
input[type=text], select, textarea {
    width: 100%;
    padding: 8px 15px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 1em;
}

input[type=submit] {
  width: 100%;
  background-color: steelblue;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type=submit]:hover {
  background-color: lightskyblue;
}
</style>
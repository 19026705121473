import { BehaviorSubject } from 'rxjs';
import axios from 'axios';
import { localStorageService } from './';

// import { router } from '../router/index';

// const baseUrl = 'api/v1/';
const accountSubject = new BehaviorSubject(null);
const authURL = process.env.VUE_APP_AUTH_URL;

export const accountService = {
    getJwtFromSSOToken,
    verifyTokenLocal,
    setAccount,
    logout,
    account: accountSubject.asObservable(),
    get accountValue () { return accountSubject.value; }
};

function getJwtFromSSOToken(ssoToken) {
    // Route auth server /verifytoken
    // appToken als Authorization Bearer
    // ssoToken als query Parameter
    return axios.get(`${authURL}/sso/verifytoken`, {
        params: {
            ssoToken
        },
        headers: { // #TODO Entfernen der hardcoded url und appToken
            'Authorization': 'Bearer 5gn0lFvYLNggY29Xtsj7w8PoCoJI'
        },
        withCredentials: true
    })
    .then((response) => {
        if (response.data.token) {
            localStorageService.setToken(response.data.token);
            return response.status;
        }
        return response.status;
        // Token speichern
    })
    .catch((error) => {
        console.log(error);
        alert('Es ist ein Fehler aufgetreten');
    })
}

function verifyTokenLocal() {
    const headers = {
        'Authorization': `Bearer ${localStorageService.getToken()}`
    };
    return axios.get(`${process.env.VUE_APP_API_BASE}/verifytoken`, {
        headers
    })
    .then((response) => {
        const userData = response.data.data;
        localStorageService.setUserData(userData);
        setAccount();
        return response.status;
    })
    .catch((error) => {
        console.log(error);
        return error;
    })
}

function setAccount() {
    const userData = localStorageService.getUserData();
    if (userData) {
        accountSubject.next(userData);
    }
}

async function logout() {
    await axios.get(`${authURL}/sso/logout`, {
        withCredentials: true
    });
    localStorage.clear();
    window.location = `${authURL}/sso/login?serviceURL=${process.env.VUE_APP_URL}`;
    // Redirect to login
}

/**
 * Den Token aus dem localStorage im lokalen Backend prüfen
 * @param {*} accessToken Token kfzapp-idea-token
 */
/*function apiAuthenticate(accessToken) {
    // Backend Funktion aufrufen
}*/